<template>
  <div style="margin-top: 2rem;">
    <router-link to="/my-events/index?view='register_assistants'">
      <Button class="btn-return title-normal mt-1 mb-2">
        <img :src="require(`@/static/img_events/left-arrow.png`)" alt="logo" height="15" width="15" class="mr-1 ml-2" />
        <span>Regresar</span>
      </Button>
    </router-link>
    <Main >
      <QrCameraComponent
        v-if="state.showReaderQR"
        v-on:responseSuccessQR="handleResponseSuccessQr"
        :idEvent="state.eventId" />

      <VerifyQrSuccessResponse 
        v-if="!state.showReaderQR"
        :responseQR="state.responseQR"
        v-on:captureAnotherQr="chageToCamera"
       />
    </Main>
  </div>
</template>

<script>
import { Main } from '../styled';
import { useStore } from 'vuex';
import { defineAsyncComponent, reactive } from 'vue';
import { useRoute } from "vue-router";

const QrCameraComponent = defineAsyncComponent(() => import('./overview/index/QrCameraComponent'));
const VerifyQrSuccessResponse = defineAsyncComponent(() => import('./overview/index/VerifyQrSuccessResponse'));

export default {
  name: 'RegisterAssistantsIndex',
  components: {
    Main,
    QrCameraComponent,
    VerifyQrSuccessResponse
  },
  async setup() {
    const { dispatch } = useStore();
    dispatch('changeTitleHeader', "Registro de asistencia");

    const state = reactive({
      eventId: useRoute().params.id,
      showReaderQR: true,
      responseQR: null,
    })


    const handleResponseSuccessQr = (request) => {
      if(request.isValid){
        state.responseQR = request.data;
        state.showReaderQR = false;
      }
    }

    const chageToCamera = (value) => {
      state.showReaderQR = value.captureAnotherOne;
    }

    return {
      handleResponseSuccessQr,
      state,
      chageToCamera
    };
  },
};
</script>
<style scoped>
.btn-return{
    background: none;
    border: none;
    color: #7B868C;
    cursor: pointer;
    margin-left: 1rem;
}
</style>
